:root {
  --header-background: #lightBlue;
  --bg_main: lightBlue;
  --text_light: #fff;
  --text_med: #53627c;
  --text_dark: #1e2432;
  --bg_switch: rgb(207, 236, 246);
  --light: #d8dbe0;
  --dark: #28292c;

  font-size: 62.5%;
}

[data-theme="dark"] {
  --header-background: #0a1f44;
  --bg_main: #0a1f44;
  --text_light: #fff;
  --text_med: #53627c;
  --text_dark: #1e2432;
  --bg_switch: #05255b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  background-color: var(--bg_main);
  color: var(--text_light);
  min-height: 100vh;
  padding: 4% 4% 4% 4%;
}
p {
  font-size: 1rem;
}

/* Navbar */ /*buttons*/
nav {
  display: flex;
  padding: 0;
  background: var(--header-background);
}
li button {
  display: inline-block;
  background: var(--text_light);
  color: var(--bg_main);
  border-radius: 4px;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  width: 100%;
  border: none;
}

button.regular {
  display: inline-block;
  background: #3993ec;
  color: white;
  border-radius: 4px;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  border: none;
  outline: none;
  margin-left: 1%;
  height: fit-content;
  font-size: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
button.delete-button {
  display: inline-block;
  border: none;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  position: absolute;
  top: 1%;
  right: 5%;
  width: auto;
  font-size: 3rem;
}
button:hover,
button:disabled,
button:disabled:hover {
  opacity: 0.5;
}

nav section {
  width: 100%;
}

nav section h1,
nav section,
section h1 {
  color: var(--text_light);
}

nav a,
nav a:active {
  font-weight: 700;
  margin: 0 2vw 0 0;
  border-radius: 4px;
  color: var(--text_light);
}

nav a:hover {
  color: var(--text_med);
}

.navContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navLinks {
  display: flex;
  font-size: 2rem;
}

/* HomeWeather */
ul {
  list-style: none;
  padding: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
}
.add-favorite-button-input-container {
  display: flex;
  align-items: flex-end;
  margin-top: 3%;
}

.weekly-title {
  font-size: 3rem;
  width: 100%;
  right: 25%;
  text-align: center;
}

/* SECTION #1
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.search-section {
  color: var(--text_light);
}

.heading {
  font-weight: bold;
  font-size: 4rem;
  letter-spacing: 0.02em;
  padding: 0 0 30px 0;
}

.search-section form {
  position: relative;
  display: flex;
  align-items: center;
}

.search-section form input,
search-section form li {
  font-size: 2rem;
  height: 40px;
  padding: 5px 5px 10px;
  border-radius: 2vw;
  border: none;
}

.search-section form input::placeholder:focus-within {
  color: currentColor;
  outline: none;
}
.search-section form input:focus {
  outline: none;
}
.search-list-select-appear {
  position: absolute;
  top: 100%;
  z-index: 1;
}
.search-list-select-disappear {
  position: absolute;
  display: none;
}

.search-list-item:hover {
  background-color: var(--bg_main);
}

.search-section form .msg {
  position: absolute;
  bottom: -40px;
  left: 0;
  max-width: 450px;
  min-height: 40px;
}

/* SECTION #2
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.ajax-section {
  margin: 3vw 0 0 0;
}

.ajax-section .cities {
  display: grid;
  grid-gap: 32px 20px;
  grid-template-columns: repeat(3, 1fr);
}

.ajax-section .city {
  position: relative;
  padding: 40px 10%;
  border-radius: 20px;
  background: var(--text_light);
  color: var(--text_med);
}

.ajax-section .city::after {
  content: "";
  width: 90%;
  height: 50px;
  position: absolute;
  bottom: -12px;
  left: 5%;
  z-index: -1;
  opacity: 0.3;
  border-radius: 20px;
  background: var(--text_light);
}

.ajax-section figcaption {
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.ajax-section .city-temp {
  font-size: 5rem;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.ajax-section .min-temp {
  font-size: 1.5rem;
  margin-left: 1vw;
}
.ajax-section .city sup {
  font-size: 0.5em;
}

.ajax-section .city-navigate {
  cursor: pointer;
}
.ajax-section .city-name sup {
  padding: 0.2em 0.6em;
  border-radius: 30px;
  color: var(--text_light);
}

.ajax-section .city-icon {
  width: 100px;
  height: 100px;
  padding: 1vw;
}

.ajax-section .error-message {
  color: white;
  font-size: 2rem;
}

/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 1000px) {
  :root {
    font-size: 50%;
  }

  .ajax-section .cities {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 700px) {
  .heading,
  .ajax-section .city-temp {
    font-size: 40%;
  }

  .ajax-section {
    margin-top: 20px;
  }

  .search-section form {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-section form input
  /* ,.search-section form button */ {
    width: 100%;
  }

  /* .search-section form button {
    margin: 20px 0 0 0;
  } */

  .search-section form .msg {
    position: static;
    max-width: none;
    min-height: 0;
    margin-top: 10px;
  }

  .ajax-section .cities {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  body {
    padding: 15px;
  }

  .ajax-section .cities {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* API Key banner
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.api {
  background: #fffbbc;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
}

.api a {
  text-decoration: underline;
}

.api a:hover {
  text-decoration: none;
}

/* spinner */

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner h1 {
  margin: 5px;
  color: white;
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: linear-gradient(
    to right,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: var(--bg_main);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* toggle switch */
.switches-continer {
  display: flex;
}
.toggle-switch {
  position: relative;
  width: 4.5vw;
}

label {
  position: absolute;
  width: 100%;
  height: 2.5vw;
  background-color: var(--dark);
  border-radius: 2vw;
  cursor: pointer;
}

/* input { */
.toggle-switch input {
  position: absolute;
  display: none;
}
.toggle-input {
  position: absolute;
  display: none;
}
.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2vw;

  transition: 0.3s;
}

input:checked ~ .slider {
  background-color: white;
}

.slider::before {
  content: "";
  position: absolute;
  top: 0.35vw;
  left: 0.35vw;
  width: 1.8vw;
  height: 1.8vw;
  border-radius: 50%;
  box-shadow: inset 0.6vw -0.05vw 0px 0px var(--light);

  background-color: var(--dark);
  transition: 0.3s;
}

input:checked ~ .slider::before {
  transform: translateX(1.9vw);
  background-color: rgb(243, 243, 13);
  box-shadow: none;
}

/* Switch */
.switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5vw;
  width: 4.5vw;
  position: relative;
  right: 5%;
}
.react-switch-checkbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}

.react-switch {
  cursor: pointer;

  background: #fff;
  position: relative;
  transition: background-color 0.2s;
  /* border-radius: 50px; */
  border-radius: 2vw;
  color: #000;
}

.react-switch .react-switch-button {
  position: absolute;
  z-index: 1;

  transition: 0.2s;
  background: var(--bg_switch);

  top: 0.35vw;
  left: 0.35vw;
  width: 55%;
  height: 70%;

  border-radius: 2vw;
}

.react-switch-checkbox:checked + .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-checkbox:active + .react-switch-button {
  /* width: 60px; */
  width: 2vw;
}

.react-switch-labels {
  display: relative;
  z-index: 0;
  height: 100%;
  font: 14pt "Segoe UI", Verdana, sans-serif;
}

.react-switch-labels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.react-switch-labels span:first-child {
  left: 0;
}
.react-switch-labels span:last-child {
  right: 0;
}
